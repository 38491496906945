import React, { useState, useEffect } from 'react';
import Paypal from 'gatsby-plugin-paypal';
import { navigate } from 'gatsby-link';
import { useCookies } from 'react-cookie';
import dateFormat, { masks } from 'dateformat';
import { URL_API } from 'gatsby-env-variables';

const PaypalButton = (props) => {
  const getInitialState = () => {
    const value = 'Monthly';
    return value;
  };
  const [subscriptionType, setSubscriptionType] = useState(getInitialState);
  const [value, setValue] = useState(1.5);
  const [currentUser, setCurrentUser] = useState(null);
  const [userId, setUserId] = useState();
  const [cookies, removeCookies, setCookie] = useCookies(['currentUser']);
  const [date, setDate] = useState(dateFormat('longDate'));

  const handleChange = (e) => {
    setSubscriptionType(e.target.value);
    if (subscriptionType == 'Monthly') {
      setValue(1.5);
    } else if (subscriptionType == 'Yearly') {
      setValue(10);
    } else {
      setValue(70);
    }
    console.log(subscriptionType);
  };

  useEffect(() => {
    setCurrentUser(cookies.currentUser);
    if (currentUser) {
      setUserId(currentUser);
    }
  }, []);

  const onSuccessMonthly = async (payment) => {
    // 1, 2, and ... Poof! You made it, everything's fine and dandy!
    console.log('Payment successful!', payment);

    navigate('/payment-success?subscription=Monthly');
  };

  const onSuccess = async (payment) => {
    // 1, 2, and ... Poof! You made it, everything's fine and dandy!
    console.log('Payment successful!', payment);

    const response = fetch(`${URL_API}/user/` + currentUser, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        //'Access-Control-Allow-Origin': "*",
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
        'Access-Control-Allow-Headers':
          'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
      body: JSON.stringify({ payment_type: subscriptionType, payment_status: 'Active', payment_start: date }),
    });

    const json = response.json;

    if (json.status == 'success') {
      alert('Payment Success');
      navigate('/account');
    } else {
      alert('Payment Failed');
    }
  };

  // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data

  const onCancel = (data) => {
    // The user pressed "cancel" or closed the PayPal popup
    console.log('Payment cancelled!', data);
    alert('Payment Failed onError');
    // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
  };

  const onError = (err) => {
    // The main Paypal script could not be loaded or something blocked the script from loading
    console.log('Error!', err);

    alert('Payment Failed onError');
    // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
    // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
  };

  let env = 'sandbox'; // you can set this string to 'production'
  let currency = 'USD'; // you can set this string from your props or state
  let total = 1; // this is the total amount (based on currency) to charge
  // Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/

  const client = {
    sandbox: 'AR0JS9qKJvBvFB5tF2ErRU00y9EVy4FboMFUP_KvYmtdqPZzRT0aYGlksasZoIrIEt8BDmNakH44E8Dt',
    production: 'AY-CgsB0CXj13ooM3uCLIX603wzkvSaSei9YaEaqd4TvJrCtBHmk0zakdbnOtXFqTVCyZuH_dLoeASQv',
  };

  return (
    <>
      <p className="text-xl font-bold mb-3">Subscription Type</p>
      <select
        className="form-select appearance-none
        block
        w-full
        px-3
        py-3
        mx-auto
        mb-5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding bg-no-repeat
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        aria-label="Default select example"
        onChange={handleChange}
        style={{ maxWidth: '750px' }}>
        <option value="Monthly">Monthly</option>
        <option value="Yearly">Yearly</option>
        <option value="Lifetime">Lifetime</option>
      </select>

      {subscriptionType == 'Yearly' ? (
        <>
          <p className="hidden">Yearly</p>
          <Paypal
            style={{
              shape: 'rect',
              color: 'blue',
              layout: 'horizontal',
              label: 'paypal',
              maxWidth: '100%',
              width: '100%',
            }}
            amount={10}
            currency="USD"
            onError={onError}
            onSuccess={onSuccess}
            onCancel={onCancel}
          />
        </>
      ) : null}
      {subscriptionType == 'Lifetime' ? (
        <>
          <Paypal
            style={{
              shape: 'rect',
              color: 'blue',
              layout: 'horizontal',
              label: 'paypal',
            }}
            amount={70}
            currency="USD"
            onError={onError}
            onSuccess={onSuccess}
            onCancel={onCancel}
          />
          <p className="hidden">Lifetime</p>
        </>
      ) : null}
      {subscriptionType == 'Monthly' ? (
        <>
          <Paypal
            style={{
              shape: 'rect',
              color: 'blue',
              layout: 'horizontal',
              label: 'paypal',
            }}
            amount={1.5}
            currency="USD"
            onError={onError}
            onSuccess={onSuccess}
            onCancel={onCancel}
          />
          <p className="hidden">Monthly</p>
        </>
      ) : null}
    </>
  );
};

export default PaypalButton;
