export const fetchJson = function (url, method, data) {
  return fetch(url, {
    method: method,
    mode: 'cors', // no-cors, cors, *same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'omit', // include, *same-origin, omit
    headers: {
      //'Access-Control-Allow-Origin': "*",
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
      'Access-Control-Allow-Headers':
        'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
    },
    redirect: 'follow', // manual, *follow, error
    referrer: 'client', // no-referrer, *client
    body: JSON.stringify(data),
  });
};

export const fetchPostJson = function (url, data) {
  return fetchJson(url, 'POST', data);
};

export const fetchGetJson = function (url, data) {
  return fetchJson(url, 'GET', data);
};
