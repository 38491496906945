import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_KEY, STRIPE_MONTHLY, STRIPE_YEARLY, STRIPE_LIFETIME } from '../env';
import { useCookies } from 'react-cookie';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(STRIPE_KEY);
  }
  return stripePromise;
};

export default function StripeButton() {
  const [subscriptionType, setSubscriptionType] = useState();
  const [cookies] = useCookies(['loggedIn', 'currentUser']);

  useEffect(() => {
    setSubscriptionType(sessionStorage.getItem('subscriptionType'));
  }, []);

  const redirectToMonthlyCheckout = async (event) => {
    event.preventDefault();
    const stripe = await getStripe();
    if (typeof window !== 'undefined') {
      const successUrl = `${window.location.origin}/payment-success?subscription=Monthly&provider=stripe&transaction_id={CHECKOUT_SESSION_ID}`;
      const { error } = await stripe.redirectToCheckout({
        mode: 'subscription',
        customerEmail: cookies?.userInfo?.email,
        lineItems: [{ price: STRIPE_MONTHLY, quantity: 1 }],
        successUrl: successUrl,
        cancelUrl: `${window.location.origin}/payment/`,
      });

      if (error) {
        console.warn('Error:', error);
        sessionStorage.setItem('subscriptionType', 'Free');
      }
    }
  };

  const redirectToYearlyCheckout = async (event) => {
    event.preventDefault();
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      mode: 'subscription',
      customerEmail: cookies?.userInfo?.email,
      lineItems: [{ price: STRIPE_YEARLY, quantity: 1 }],
      successUrl: `${window.location.origin}/payment-success?subscription=Yearly&provider=stripe&transaction_id={CHECKOUT_SESSION_ID}`,
      cancelUrl: `${window.location.origin}/payment/`,
    });

    if (error) {
      console.warn('Error:', error);
      sessionStorage.setItem('subscriptionType', 'Free');
    }
  };

  const redirectToLifetimeCheckout = async (event) => {
    event.preventDefault();
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      mode: 'payment',
      customerEmail: cookies?.userInfo?.email,
      lineItems: [{ price: STRIPE_LIFETIME, quantity: 1 }],
      successUrl: `${window.location.origin}/payment-success?subscription=Lifetime&provider=stripe&transaction_id={CHECKOUT_SESSION_ID}`,
      cancelUrl: `${window.location.origin}/payment/`,
    });

    if (error) {
      console.warn('Error:', error);
      sessionStorage.setItem('subscriptionType', 'Free');
    }
  };

  return (
    <>
      <p className="text-xl font-bold mb-5">Subscription Type: {subscriptionType}</p>

      {subscriptionType === 'Monthly' ? (
        <button onClick={redirectToMonthlyCheckout} className="rounded-md font-opensans px-10 py-3 bg-[#0070ba] text-white">
          Checkout
        </button>
      ) : subscriptionType === 'Yearly' ? (
        <button onClick={redirectToYearlyCheckout} className="rounded-md font-opensans px-10 py-3 bg-[#0070ba] text-white">
          Checkout
        </button>
      ) : (
        <button onClick={redirectToLifetimeCheckout} className="rounded-md font-opensans px-10 py-3 bg-[#0070ba] text-white">
          Checkout
        </button>
      )}
    </>
  );
}
