import React, { useState, useEffect, useRef } from 'react';
import Helmet from 'react-helmet';
import { navigate } from 'gatsby-link';
import { useCookies } from 'react-cookie';
import dateFormat from 'dateformat';

function PaypalCheckout() {
  const url = typeof window !== 'undefined' ? window.location.origin : '';

  const [subscriptionType, setSubscriptionType] = useState(sessionStorage.getItem('subscriptionType'));
  const paypalRef = useRef();
  const [paid, setPaid] = useState(false);
  const [error, setError] = useState(null);
  const [cookies, setCookies] = useCookies(['currentUser']);
  const [value, setValue] = useState(sessionStorage.getItem('subscriptionAmount'));
  const [date, setDate] = useState(dateFormat('longDate'));
  const [currentUser, setCurrentUser] = useState(cookies.currentUser);

  useEffect(() => {
    if (subscriptionType == 'Monthly') {
      if (typeof window !== 'undefined') {
        window.paypal
          .Buttons({
            style: {
              shape: 'rect',
              color: 'gold',
              layout: 'vertical',
              label: 'subscribe',
            },
            createSubscription: function (data, actions) {
              return actions.subscription.create({
                /* Creates the subscription */
                plan_id: 'P-8HX78870L6792751UMLU5DLI',
              });
            },
            onApprove: async function (data, actions) {
              const order = await actions.order.capture();
              setPaid(true);
              console.log(order);
              navigate(
                `/payment-success/?subscription=${subscriptionType}&provider=paypal&transaction_id=${data.subscriptionID}&customer_id=${order?.player?.player_id}`
              );
            },
            onError: (err) => {
              setError(err);
              console.error(err);
              alert(err);
              // sessionStorage.setItem('subscriptionType', 'Free');
            },
          })
          .render(paypalRef.current);
      }
    }

    if (subscriptionType == 'Yearly') {
      if (typeof window !== 'undefined') {
        window.paypal
          .Buttons({
            style: {
              shape: 'rect',
              color: 'gold',
              layout: 'vertical',
              label: 'subscribe',
            },
            createSubscription: function (data, actions) {
              return actions.subscription.create({
                /* Creates the subscription */
                plan_id: 'P-90K97377GC489082VMLU5EEQ',
              });
            },
            onApprove: async function (data, actions) {
              const order = await actions.order.capture();
              setPaid(true);
              console.log(order);
              navigate(
                `/payment-success/?subscription=${subscriptionType}&provider=paypal&transaction_id=${data.subscriptionID}&customer_id=${order?.player?.player_id}`
              );
            },
            onError: (err) => {
              setError(err);
              console.error(err);
              alert(err);
              // sessionStorage.setItem('subscriptionType', 'Free');
            },
          })
          .render(paypalRef.current); // Renders the PayPal button
      }
    }

    if (subscriptionType == 'Lifetime') {
      if (typeof window !== 'undefined') {
        window.paypal
          .Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                intent: 'CAPTURE',
                purchase_units: [
                  {
                    description: `${subscriptionType} Subscription`,
                    amount: {
                      currency_code: 'USD',
                      value: value,
                    },
                  },
                ],
              });
            },
            onApprove: async (data, actions) => {
              const order = await actions.order.capture();
              setPaid(true);
              console.log(order);
              navigate(
                `/payment-success/?subscription=${subscriptionType}&provider=paypal&transaction_id=${data.subscriptionID}&customer_id=${order?.player?.player_id}`
              );
            },
            onError: (err) => {
              //   setError(err),
              console.error(err);
              // sessionStorage.setItem('subscriptionType', 'Free');
            },
          })
          .render(paypalRef.current);
      }
    }
  }, []);

  <Helmet>
    <script
      src="https://www.paypal.com/sdk/js?client-id=AY-CgsB0CXj13ooM3uCLIX603wzkvSaSei9YaEaqd4TvJrCtBHmk0zakdbnOtXFqTVCyZuH_dLoeASQv&vault=true&intent=subscription"
      data-sdk-integration-source="button-factory"></script>
  </Helmet>;

  return (
    <>
      <p className="text-xl font-bold mb-5">Subscription Type: {subscriptionType}</p>
      <div ref={paypalRef} />
    </>
  );
}

export default PaypalCheckout;
