import React, { useEffect, useState } from 'react';
import { FaFacebookF } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { navigate } from 'gatsby-link';
import { useCookies } from 'react-cookie';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const NavigationDark = () => {
  const [currentUser, setCurrentUser] = useState();
  const [cookies, removeCookies] = useCookies(['currentUser', 'loggedIn']);

  useEffect(() => {
    setCurrentUser(cookies.currentUser);
  }, []);

  const logOut = () => {
    removeCookies('loggedIn', '', { path: '/' });
    removeCookies('currentUser', '', { path: '/' });
    navigate('/');
  };

  return (
    <>
      <div className="w-screen absolute py-7 z-10 xl:bg-none xl:bg-opacity-0 navbar-bg-color  xl:px-28 px-7">
        <div className="flex justify-between items-center col-span-full">
          <Link to="/" className="xl:block hidden">
            <StaticImage
              className="w-full xl:max-w-[130px] max-w-[100px] mx-auto xl:mt-1"
              src={'../../images/logo-black.png'}
              formats={['auto', 'webp', 'avif']}
              width={500}
              alt="Girar Logo"
              placeholder="blurred"
            />
          </Link>

          <Link to="/" className="xl:hidden block">
            <StaticImage
              className="w-full xl:max-w-[130px] max-w-[100px] mx-auto xl:mt-1"
              src={'../../images/logo-white.png'}
              formats={['auto', 'webp', 'avif']}
              width={500}
              alt="Girar Logo"
              placeholder="blurred"
            />
          </Link>
          <div className="flex">
            <div className="xl:hidden">
              {currentUser ? (
                <li className="flex items-center text-white w-full align-center">
                  <button
                    className="xl:px-5 py-1 px-5 rounded-md bottom-10 btn-xs button-white font-bold font-opensans text-base transition-all duration-500 focus:bg-limed-oak-550"
                    onClick={logOut}>
                    Logout
                  </button>
                </li>
              ) : (
                <li className="flex items-center text-white w-full align-center">
                  <Link to="/login">
                    <button className="xl:px-5 py-1 px-5 button-white btn-xs rounded-md bottom-10  font-bold font-opensans text-base transition-all duration-500 focus:bg-limed-oak-550">
                      Login
                    </button>
                  </Link>
                </li>
              )}
            </div>
            <div className="xl:flex hidden">
              <a target="_blank" rel="noreferrer" href="https://www.facebook.com/WeltgeistKiran">
                {' '}
                <FaFacebookF size="25" className="mx-3 mt-3" />{' '}
              </a>
              <a target="_blank" rel="noreferrer" href="https://twitter.com/WeltgeistKiran">
                {' '}
                <FaTwitter size="25" className="mx-3 mt-3 " />{' '}
              </a>
              <a target="_blank" rel="noreferrer" href="https://www.instagram.com/originalsin_0421/">
                {' '}
                <FaInstagram size="25" className="mx-3 mt-3" />{' '}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavigationDark;
