import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { navigate } from 'gatsby-link';
import { URL_API, RAZORPAY_KEY, URL_ASSETS, URL_BASE } from '../env';
import { SpinnerCircular } from 'spinners-react';
import { fetchJson } from '../helper/fetch-helper';

function RazorPayCheckout() {
  const [subscriptionType, setSubscriptionType] = useState(sessionStorage.getItem('subscriptionType'));

  const initialformData = Object.freeze({
    user_id: '',
    avatar: '',
    display_name: '',
    name: '',
    phone_number: '',
    email: '',
    password: '',
    new_password: '',
    old_password: '',
    password_confirmation: '',
    payment_type: '',
    payment_status: '',
  });
  const [formData, setFormData] = useState(initialformData);
  const [cookies] = useCookies(['loggedIn', 'currentUser']);
  const [currentUser, setCurrentUser] = useState(null);
  const [avatar2, setAvatar2] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subscriptionStart, setSubscriptionStart] = useState(0);
  const [subscriptionEnd, setSubscriptionEnd] = useState(0);

  const url = typeof window !== 'undefined' ? window.location.origin : '';

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    setCurrentUser(cookies.currentUser);
    const loggedIn = cookies.loggedIn;
    if (loggedIn != 'true') {
      navigate('/login');
    }
  }, []);

  const retrieveUserData = async () => {
    console.log('retrieveUserData: currentUser: ', currentUser);
    if (currentUser) {
      const response = fetchJson(`${URL_API}/user/` + currentUser, 'GET')
        .then((response) => {
          return response.json();
        })
        .then((events) => {
          setAvatar2(events?.data?.avatar);
          return events.data;
        });
      return response;
    }
  };

  useEffect(() => {
    //setLoading(true);
    const getUser = async () => {
      const selectedUser = await retrieveUserData();
      if (selectedUser) {
        setFormData({
          ...formData,
          user_id: selectedUser.id,
          avatar: selectedUser.avatar,
          display_name: selectedUser.display_name,
          name: selectedUser.name,
          phone_number: selectedUser.phone_number,
          email: selectedUser.email,
          password: selectedUser.password,
          new_password: selectedUser.new_password,
          old_password: selectedUser.old_password,
          new_password_confirm: selectedUser.new_password_confirm,
          payment_type: selectedUser.payment_type,
          payment_status: selectedUser.payment_status,
          payment_start: selectedUser.payment_start,
          payment_end: selectedUser.payment_end,
        });
      }
    };
    getUser();
  }, [currentUser]);

  const getSubscription = async (userData) => {
    var formData = new FormData();
    formData.append('x', 'hello');

    const response = await fetchJson(`${URL_API}/payment/razorpay/create-subscription`, 'POST', { userData, type: subscriptionType });

    const json = await response.json();
    setLoading(false);
    return json;
  };

  const displayRazorpay = async (e) => {
    e.preventDefault();
    setLoading(true);
    const selectedUser = await retrieveUserData();
    const dataSubcription = await getSubscription(selectedUser);

    setLoading(false);

    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    const options = {
      key: RAZORPAY_KEY,
      subscription_id: dataSubcription.data.payment_id,
      name: 'Girar World',
      description: '',
      image: `${URL_API}/assets/images/logo-black.png`,

      handler: async function (response) {
        const responseSignature = await fetchJson(`${URL_API}/payment/razorpay/signature`, 'POST', {
          user_id: selectedUser.id,
          payment_status: 'Active',
          payment_start: Date.now(),
          payment_end: dataSubcription.data.payment_expire,
          payment_type: subscriptionType,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
          razorpay_subscription_id: dataSubcription.data.payment_id,
        });

        const json = await responseSignature.json();
        console.log({ json });
        if (json.status === 'success') {
          console.log('payment success');
          if (typeof window !== 'undefined') {
            window.location.replace(
              `${URL_BASE}/payment-success/?subscription=${subscriptionType}&provider=razorpay&transaction_id=${dataSubcription.data.payment_id}`
            );
          }
        } else {
          alert('Payment failed');
        }
      },
      prefill: {
        name: formData.name,
        email: formData.email,
        contact: formData.phone_number,
      },
    };

    if (typeof window !== 'undefined') {
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
  };

  return (
    <>
      <p className="text-xl font-bold pb-[28px]">Subscription Type: {subscriptionType}</p>
      {loading ? (
        <button
          onClick={(e) => {
            e.preventDefault();
          }}
          className="rounded-md font-opensans px-10 py-3 bg-[#0070ba] text-white">
          <SpinnerCircular size={'16px'} color={'white'} />
        </button>
      ) : (
        <>
          <button id="rzp-button1" onClick={displayRazorpay} className="rounded-md font-opensans px-10 py-3 bg-[#0070ba] text-white">
            Checkout
          </button>
        </>
      )}
    </>
  );
}

export default RazorPayCheckout;
