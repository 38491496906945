import React, { useState } from 'react';
import NavigationDark from '../components/navigation/NavigationDark';
import { Helmet } from 'react-helmet';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';
import PaypalButton from '../components/PaypalButton';
import StripeButton from '../components/StripeButton';
import OGImage from '../images/favicon-black.png';
import PaypalCheckout from '../components/PaypalCheckout';
import RazorpayButton from '../components/RazorpayButton';

const PaymentPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        payment_bg: file(relativePath: { eq: "paper-bg.png" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `
  );

  const url = typeof window !== 'undefined' ? window.location.origin : '';

  const [isRazorPay, setIsRazorPay] = useState(false);
  const [isPaypal, setIsPaypal] = useState(false);
  const [isStripe, setIsStripe] = useState(false);
  const bg_payment = getImage(data.payment_bg);

  // Use like this: w-screen h-full bg-paper-bg bg-cover bg-center
  const paymentBg = convertToBgImage(bg_payment);

  const RazorpayFormVisible = (e) => {
    setIsRazorPay(true);
    setIsPaypal(false);
    setIsStripe(false);
  };

  const PaypalFormVisible = (e) => {
    setIsRazorPay(false);
    setIsPaypal(true);
    setIsStripe(false);
  };

  const StripeFormVisible = (e) => {
    setIsRazorPay(false);
    setIsPaypal(false);
    setIsStripe(true);
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${url}/payment`} />
        <meta http-equiv="Content-Type" content="text/xml; charset=UTF-8" />
        <meta property="og:title" content="Payment | Girar World" />
        <meta property="description" content="Girar, go on a journey" />
        <meta property="og:description" content="Girar, go on a journey" />
        <meta property="og:url" content={`${url}/payment`} />
        <meta property="og:image" content={`${url}${OGImage}`} />
        <meta name="twitter:url" content={`${url}/payment`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@WeltgeistKiran" />
        <meta name="twitter:title" content="Payment | Girar World" />
        <meta name="twitter:description" content="Girar, go on a journey" />
        <meta property="twitter:image" content={`${url}${OGImage}`} />
        <meta property="og:type" content="website" />
        <title> Payment | Girar World </title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Rokkitt:wght@400;500;600;700;800;900&family=Telex&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <NavigationDark />
      <BackgroundImage
        Tag="section"
        className="bg-center"
        // Spread bgImage into BackgroundImage:
        {...paymentBg}>
        <section className="relative w-screen pl-5 px-5 pt-5 border-t-8 border-harvest-gold">
          <form>
            <div className="grid md:grid-cols-12 max-w-screen-xl xl:px-14 px-0 pt-28 mx-auto mb-10">
              <div className="w-full col-span-12">
                <h2 className="font-rokkit font-bold mb-2 text-4xl xl:text-5xl">Payment</h2>
                <p className="text-base mb-8">All transaction are secure and encrypted.</p>
              </div>
              <div className="col-span-12 rounded-md border border-black relative" id="credit-card">
                {/* RazorpayButton */}
                <div className="grid grid-cols-12 xl:p-5 px-5 py-3 border-b cursor-pointer" onClick={RazorpayFormVisible}>
                  <div className="col-span-12 text-center">
                    <StaticImage
                      className=""
                      src={'../images/razorpay-logo.png'}
                      formats={['auto', 'webp', 'avif']}
                      width={104}
                      alt=""
                      placeholder="blurred"
                    />
                  </div>
                </div>

                {isRazorPay ? (
                  <div className="px-5 pt-5 pb-7 border-b border-black text-center">
                    <RazorpayButton />
                  </div>
                ) : null}

                {/* StripeButton */}
                <div className="grid grid-cols-12 xl:p-5 px-5 py-3 border-b cursor-pointer" onClick={StripeFormVisible}>
                  <div className="col-span-12 text-center">
                    <StaticImage
                      className=""
                      src={'../images/stripe-img.png'}
                      formats={['auto', 'webp', 'avif']}
                      width={104}
                      alt=""
                      placeholder="blurred"
                    />
                  </div>
                </div>

                {isStripe ? (
                  <div className="px-5 pt-5 pb-7 border-b border-black text-center">
                    <StripeButton />
                  </div>
                ) : null}

                {/* PaypalButton */}
                <div className="grid grid-cols-12 border-b-2 xl:p-5 px-5 py-3 cursor-pointer" onClick={PaypalFormVisible}>
                  <div className="col-span-12 text-center">
                    <StaticImage
                      className=""
                      src={'../images/paypal-img.png'}
                      formats={['auto', 'webp', 'avif']}
                      width={104}
                      alt=""
                      placeholder="blurred"
                    />
                  </div>
                </div>

                {isPaypal ? (
                  <div className="p-5 border-black text-center">
                    <div className="text-center mt-3">
                      <PaypalCheckout />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </form>
        </section>
      </BackgroundImage>
    </>
  );
};

export default PaymentPage;
